import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Api from '@common/services/api';
import { useAppSelector } from '@common/hooks/redux';
import { selected } from '@modules/organisation/selectors/organisation';

import ModalContent from '@common/components/modal/modal-content';
import ModalWindow from '@common/components/modal/modal-window';
import { Anniversary, Birthday, Celebration } from './types';
import CelebrationDateGroup from './celebration-date-group';
import Spinner from '@common/components/spinner';
import { AlertService } from '@common/services/alert';

interface Props {
  open: boolean;
  onClose: () => void;
  networkIds?: string[];
  functionIds?: string[];
}

type BirthdaysResponse = {
  data: Record<string, Birthday[]>
};
type AnniversariesResponse = {
  data: Record<string, Anniversary[]>
};

enum CelebrationTab {
  Birthdays = 'birthdays',
  Anniversaries = 'anniversaries',
}

const CelebrationsModal = ({ open, onClose, networkIds, functionIds }: Props) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<CelebrationTab>(CelebrationTab.Birthdays);
  const [birthdays, setBirthdays] = useState<Record<string, Birthday[]>>({});
  const [anniversaries, setAnniversaries] = useState<Record<string, Anniversary[]>>({});
  const [loading, setLoading] = useState({
    birthdays: false,
    anniversaries: false,
  });

  const selectedOrganisation = useAppSelector(selected);

  useEffect(() => {
    fetchBirthdays();
    fetchAnniversaries();
  }, [networkIds, functionIds]);

  const fetchBirthdays = useCallback(async () => {
    try {
      const query = Api.utils.toQuery({
        network_ids: networkIds,
        function_ids: functionIds,
      });
      setLoading((prevLoading) => ({ ...prevLoading, birthdays: true }));
      const data = await Api.get<BirthdaysResponse>(`/v1/organisations/${selectedOrganisation.id}/birthdays?${query}`);
      setBirthdays(data.data);
    } catch (error) {
      AlertService.error(t('social:error_fetching_birthdays'));
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, birthdays: false }));
    }
  }, [selectedOrganisation.id, networkIds, functionIds]);

  const fetchAnniversaries = useCallback(async () => {
    try {
      const query = Api.utils.toQuery({
        network_ids: networkIds,
        function_ids: functionIds,
      });
      setLoading((prevLoading) => ({ ...prevLoading, anniversaries: true }));
      const data = await Api.get<AnniversariesResponse>(`/v1/organisations/${selectedOrganisation.id}/anniversaries?${query}`);
      setAnniversaries(data.data);
    } catch (error) {
      AlertService.error(t('social:error_fetching_anniversaries'));
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, anniversaries: false }));
    }
  }, [selectedOrganisation.id, networkIds, functionIds]);

  const renderHeaderContent = () => {
    return (
      <div className="TopNavigationBar__Tabs !tw-mb-[-16px]">
        <div
          className={`TopNavigationBar__Tabs__Item 
            ${activeTab === CelebrationTab.Birthdays ? 'TopNavigationBar__Tabs__Item--active' : ''} tw-cursor-pointer`}
          onClick={() => setActiveTab(CelebrationTab.Birthdays)}
        >
          {t('social:birthdays')}
        </div>
        <div
          className={`TopNavigationBar__Tabs__Item 
            ${activeTab === CelebrationTab.Anniversaries ? 'TopNavigationBar__Tabs__Item--active' : ''} tw-cursor-pointer`}
          onClick={() => setActiveTab(CelebrationTab.Anniversaries)}
        >
          {t('social:anniversaries')}
        </div>
      </div>
    );
  };

  const activeData: Record<string, Celebration[]> = activeTab === CelebrationTab.Birthdays ? birthdays : anniversaries;
  const isLoading = activeTab === CelebrationTab.Birthdays ? loading.birthdays : loading.anniversaries;

  const dates = Object.keys(activeData).sort((a, b) => moment(a).diff(moment(b)));

  return (
    <ModalWindow show={open} onHide={onClose}>
      <ModalContent
        title={t('social:celebrations_modal_title')}
        hideFooter
        onHide={onClose}
        headerContent={renderHeaderContent()}
      >
        <div className="BirthdaysList !tw-mt-[-16px]">
          {isLoading ? (
            <Spinner centered />
          ) : (
            dates.map((key) => (
              <div key={key}>
                <CelebrationDateGroup
                  key={key}
                  date={moment(key)}
                  celebrations={activeData[key]}
                  view={activeTab}
                />
              </div>
            ))
          )}
        </div>
      </ModalContent>
    </ModalWindow>
  );
};

export default CelebrationsModal;
