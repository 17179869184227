import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/core/selectors/logged-user';
import { selected as selectedOrganisation } from '@modules/organisation/selectors/organisation';

import { capitalize } from 'lodash';
import moment, { Moment } from 'moment';

import { Celebration } from './types';
import Profile from '@common/components/profile';

const dateFormats = {
  sameDay: '[Vandaag] · D MMM',
  nextDay: '[Morgen] · D MMM',
  nextDays: 'DDD D MMM',
  nextWeek: 'dddd · D MMM',
  sameElse: 'D MMM',
};

type Props = {
  date: Moment;
  celebrations: Celebration[];
  view: 'birthdays' | 'anniversaries';
};

const CelebrationDateGroup: React.FC<Props> = ({ date, celebrations, view }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isToday = date.isSame(moment(), 'date');
  const loggedUser = useAppSelector(selected);
  const organisation = useAppSelector(selectedOrganisation);
  const selectedNetwork = useAppSelector((store) => store.network.selected);

  const dateClassNames = ['BirthdaysList__Date'];
  if (isToday) dateClassNames.push('BirthdaysList__Date--today');

  const handleCreateConversation = useCallback(async (user: Celebration) => {
    history.push(`/conversations/users/${user.id}`);
  }, [dispatch, history, organisation]);

  const handleProfileClick = useCallback((user: Celebration) => {
    history.push(`/networks/${selectedNetwork}/users/${user.id}/about`);
  }, [history, selectedNetwork]);

  return (
    <div>
      <div className="tw-bg-gray-custom-100 tw-text-gray-custom-700 tw-text-sm
        tw-px-4 tw-py-3 tw-uppercase tw-font-semibold tw-tracking-[1px]"
      >
        {capitalize(moment(date).calendar(undefined, dateFormats))}
      </div>
      {celebrations.map((user: Celebration) => (
        <div
          className="tw-flex tw-items-center tw-justify-between tw-mx-[20px] tw-py-[12px]
            tw-border-b tw-border-x-0 tw-border-t-0 tw-border-gray-100 tw-border-solid"
          key={user.id}
        >
          {view === 'birthdays' ? (
            <div onClick={() => handleProfileClick(user)} className="tw-cursor-pointer">
              <Profile size={40} user={user} />
            </div>
          ) : (
            <div onClick={() => handleProfileClick(user)} className="tw-cursor-pointer">
              <Profile size={40} user={user}>
                <p className="tw-text-gray-custom-430 tw-m-0 tw-pt-1">
                  {t('social:anniversary_in_years', { count: user.year })}
                </p>
              </Profile>
            </div>
          )}
          {user.id !== loggedUser.id && isToday && (
            <div
              className="tw-bg-blue-light tw-rounded-md tw-p-2 tw-hover:tw-bg-blue-dull tw-cursor-pointer"
              onClick={() => handleCreateConversation(user)}
            >
              <img src="/static/images/chat/paper-plane-2 1.svg?url" alt="Send message" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CelebrationDateGroup;
