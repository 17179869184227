import { useAppSelector } from '@common/hooks/redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import Api from '@common/services/api';
import { selected } from '@modules/organisation/selectors/organisation';
import { Anniversary, Birthday, CelebrationsResponse } from '@modules/social/components/celebrations-widget/types';

type UseCelebrationsProps = {
  network_ids?: string[];
  function_ids?: string[];
};

export const useCelebrations = ({ network_ids, function_ids }: UseCelebrationsProps) => {
  const { t } = useTranslation();
  const [birthdays, setBirthdays] = useState<Birthday[]>([]);
  const [anniversaries, setAnniversaries] = useState<Anniversary[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const selectedOrganisation = useAppSelector(selected);

  const query = Api.utils.toQuery({
    network_ids,
    function_ids,
  });

  const fetchCelebrations = useCallback(async () => {
    try {
      setLoading(true);

      const response = await Api.get<CelebrationsResponse>(`/v1/organisations/${selectedOrganisation.id}/celebrations?${query}`);
      if (response.data?.birthdays && response.data.birthdays.length > 0) {
        setBirthdays(response.data.birthdays);
      } else {
        setBirthdays([]);
      }
      if (response.data?.anniversaries) {
        setAnniversaries(response.data.anniversaries);
      } else {
        setAnniversaries([]);
      }
    } catch (error) {
      setBirthdays([]);
      setAnniversaries([]);
    } finally {
      setLoading(false);
    }
  }, [query, t, selectedOrganisation.id]);

  useEffect(() => {
    fetchCelebrations();
  }, [fetchCelebrations]);

  return {
    birthdays,
    anniversaries,
    loading,
    fetchCelebrations,
    celebrations: [...birthdays, ...anniversaries],
  };
};

